import React from 'react'
import { Col, FormGroup, Modal, ModalBody, ModalHeader, Label, Input, Row, ModalFooter, Button, FormFeedback, FormText } from 'reactstrap'
import Datetime from 'react-datetime';
import { ModalButtonCancel } from '../../constant';

export const ModalExternalLaboratories = (props) => {

    const {
        modalNewPrice, toggleModalNewPrice, modalTitle, id_exam, price, from_price, to_price,
        handleInputChangeNewPrice, handlePickerValues, saveCreatePrice,

        loading, validationNewPrice
    } = props;

    const {
        price_valid,
        from_valid
    } = validationNewPrice;

    return (
        <Modal isOpen={modalNewPrice} toggle={toggleModalNewPrice} backdrop='static' keyboard={false} centered={true}>
            <ModalHeader toggle={toggleModalNewPrice} className='bg-primary'>
                {modalTitle}
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col sm="12" md="12" lg="12">
                        <Label>Examen</Label>
                        <br />
                        <p><b>{id_exam.label !== undefined ? id_exam.label : ""}</b></p>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" md="12" lg="12">
                        <FormGroup>
                            <Label className='col-form-label'>Precio <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                            <Input type='number' className='form-control form-control-sm input-air-primary' name="price" value={price} step="any"
                                onChange={handleInputChangeNewPrice} invalid={price_valid}
                            />
                            <FormFeedback>Debes de completar este campo</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col sm="6" md="6" lg="6">
                        <FormGroup>
                            <Label className='col-form-label'>Valido desde <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                            <Datetime
                                inputProps={{
                                    placeholder: 'dd-mm-aaaa',
                                    name: 'from_price',
                                    autoComplete: "off",
                                    className: "form-control form-control-sm input-air-primary"
                                }}
                                timeFormat="HH:mm:ss"
                                timeConstraints={{
                                    hours: { min: 0, max: 23 },
                                    minutes: { min: 0, max: 59 },
                                    seconds: { min: 0, max: 59 }
                                }}
                                value={from_price}
                                dateFormat={true}
                                locale="es"
                                onChange={(e) => handlePickerValues(e, "from_price")}
                            />
                            <FormText color='danger' hidden={!from_valid}>Debes de completar este campo</FormText>
                        </FormGroup>
                    </Col>
                    <Col sm="6" md="6" lg="6">
                        <FormGroup>
                            <Label className='col-form-label'>Valido hasta </Label>
                            <Datetime
                                inputProps={{
                                    placeholder: 'dd-mm-aaaa ',
                                    name: 'to_price',
                                    autoComplete: "off",
                                    className: "form-control digits form-control-sm",
                                    min: { from_price }
                                }}
                                timeFormat="HH:mm:ss"
                                timeConstraints={{
                                    hours: { min: 0, max: 23 },
                                    minutes: { min: 0, max: 59 },
                                    seconds: { min: 0, max: 59 }
                                }}
                                value={to_price}
                                dateFormat={true}
                                locale="es"
                                onChange={(e) => handlePickerValues(e, "to")}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button size='sm' outline color='primary' type='button' className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""} onClick={saveCreatePrice}>
                    Guardar
                </Button>
                <Button size="sm" outline color='danger' type="button" onClick={toggleModalNewPrice}>{ModalButtonCancel}</Button>
            </ModalFooter>
        </Modal>
    )

}

import React, { Fragment } from 'react'
import { Card, Col, Container, Row, Button, CardBody, FormGroup, Label, Table, Input, InputGroupAddon, InputGroupText, InputGroup, FormFeedback, CardHeader, Nav, NavItem, FormText } from 'reactstrap';
import { examReference, codeadd, descriptionadd, costoadd, recepcion, accionesadd, profileExam, ButtonGoBack, save, update, PlaceHolderOption } from '../../../constant';
import { useUpdateLaboratories } from '../../../hooks/laboratoriesExternal/useUpdateLaboratories';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';

import './StyleLaboratoriesExternos.css';
import { ModalExternalLaboratories } from '../../../components/ExternalLaboratories/ModalExternalLaboratories';

export const IndexUpdateLaboratories = () => {

	const history = useHistory();

	const { 
		//MODALS
		modalTitle, modalNewPrice, toggleModalNewPrice, loading,

		//MODELS NEW PRICE
		id_exam, price, from_price, to_price,

		//MODELS
        externalTest,

        //LIST
        informationLaboratory , listExams, week,

        //HANDLE METHODS
		handleInputChangeNewPrice, handlePickerValues,

        handleSelectExamChange, handleChangeTypeInputs, handleDeleteTestReception,
		handleAddTestReception,

		//REQUEST METHODS
        saveCreatePrice, saveRange,
		
		//VALIDATIONS 
        validationNewPrice

	} = useUpdateLaboratories();

	return (
		<Fragment>
			<Container fluid={true}> <br />
				<Row>
					<Col sm="12" md="12" lg="12" xl="12" className='project-list'>
						<Card>
							<Row>
								<Col sm="12" md="12" lg="12">
									<Nav tabs className='border-tabs'>
										<NavItem className='f-14'>
											<Button color="light" size='sm' className="float-right mr-1 ml-1" onClick={() => history.goBack()}><i className="icofont icofont-arrow-left"></i> {"Regresar"}</Button>
										</NavItem>
										<NavItem className='f-14 pt-2'>&nbsp;&nbsp;&nbsp; / Laboratorio de referencia</NavItem>
										<NavItem className='f-14 pt-2'>&nbsp;&nbsp;&nbsp; / &nbsp;&nbsp;&nbsp;  </NavItem>
										<NavItem className='f-14 pt-2'>{informationLaboratory.name !== undefined ? informationLaboratory.name : ""}  </NavItem>
									</Nav>
								</Col>
							</Row>
						</Card>
					</Col>
				</Row>
				<Row>
					<Col md="12">
							
							<CardBody className="p-3">
								<Row>
									<Col md="12">
										<FormGroup className="p-3">
											<Label>{profileExam}</Label>
											<Select
												className="input-air-primary input-xs"
												classNamePrefix="select"
												name="id_exam"
												value={id_exam}
												options={listExams}
												placeholder={PlaceHolderOption}
												isClearable={true}
												onChange={(e) => handleSelectExamChange(e)}
											/>
										</FormGroup>
									</Col>
								</Row>		
								<Row>
									<Col sm="12" md="12" lg="12" xl="12">
										<Card>
										<div className='table-responsive'>
											{
												externalTest.length === 0 ? <div style={{ textAlign: "-webkit-center" }}>
														<img className="img-fluid" src={require("../../../assets/images/loginHelenLabs/Alerts/Alert.svg")} style={{ width: "20%" }} alt="" />   <br />
														<br />
														<p className='f-18'>No se ha registrado ningun examen</p>
													</div>
												: 
													<Table lassName="table-border-vertical">
														<thead className="theadA">
															<tr className='border-bottom-info'>
																<th  className='labelThead' scope='col'>{codeadd}</th>
																<th   className='labelThead' scope='col'>{descriptionadd}</th>
																<th  className='labelThead'  scope='col'>{costoadd}</th>
																<th  className='labelThead' scope='col' >Valido apartir de </th>
																<th  className='labelThead' scope='col' >Valido hasta </th>
															</tr>
														</thead>
														<tbody>
															{
																externalTest.length > 0 && externalTest.map(_test => {
																	return (
																		<>
																			<tr key={_test.id_tmp_external} className='border-bottom-info'>
																				<td style={{ verticalAlign: "middle" }} className='p-l-0 p-r-0 text-center'>{ _test.code_exam }</td>
																				<td style={{ verticalAlign: "middle" }} className='p-l-0 p-r-0'>{ _test.name_exam }</td>
																				<td style={{ verticalAlign: "middle" }} className='p-l-0 p-r-0'>
																					<span className='link text-sm text-primary badge badge-light-primary f-12'>$ { _test.price } </span>
																				</td>
																				<td style={{ verticalAlign: "middle" }} className='p-l-0 p-r-0 text-center'>																				
																					<span className='link text-sm text-primary badge badge-light-primary f-12'>{ _test.valid_from }</span>
																				</td>
																				<td style={{ verticalAlign: "middle" }} className='p-l-0 p-r-0 text-center'>
																					<span className='link text-sm text-primary badge badge-light-primary f-12'>{ _test.valid_to === "" ? "A la fecha" : _test.valid_to } </span>
																				</td>
																			</tr>
																			<tr className='border-bottom-info'>
																				<td colSpan={5}>
																					{
																						_test.listRanges.length > 0 && _test.listRanges.map(_range => {
																							return (
																								<Row key={_range.id_external_reception}>
																									 <Col sm="3" md="3" lg="3" style={{ verticalAlign: "middle" }} className='p-l-0 p-r-0 text-center'>
																										Rangos de recepción
																									 </Col>
																									 <Col sm="3" md="3" lg="3">
																										<FormGroup className='p-r-0 p-l-0' size="sm">
																											<Label><b>Días de la semana</b></Label>
																											<Select
																												isMulti
																												name="days_of_week"
																												value={_range.days_of_week}
																												options={week}
																												classNamePrefix="select input-sm"
																												placeholder={PlaceHolderOption}
																												isSearchable={true}
																												className="input-air-primary b-r-9"
																												styles={
																													{
																														placeholder: () => ({
																															color: "#7a7979"
																														})
																													}
																												}
																												onChange={(e) => handleChangeTypeInputs(e, _test.id_exam, "days_of_week", _range.id_external_reception)}
																											/>
																											<FormText color='danger' hidden={_range.days_of_week.length === 0 ? false : true }>Selecciona al menos una opción</FormText>
																										</FormGroup>
																									 </Col>
																									 <Col sm="3" md="3" lg="3">
																										<FormGroup className='p-r-0 p-l-0'>
																											<Label><b>Desde</b></Label>
																											<Input type='time' name="from" autoComplete='off' size="sm" className='form-control form-control-sm input-air-primary' value={_range.from}																											
																												onChange={(e) => handleChangeTypeInputs(e, _test.id_exam, "from", _range.id_external_reception)} invalid={_range.from === "" ? true : false}
																											/>
																											<FormFeedback>Debes llenar este campo</FormFeedback>
																										</FormGroup>
																									 </Col>
																									 <Col sm="3" md="3" lg="3">
																										<FormGroup className='p-r-0 p-l-0'>
																											<Label><b>Hasta</b></Label>
																											<InputGroup size='sm'>
																												<Input type="time" name="to" autoComplete='off' className='form-control form-control-sm input-air-primary' value={_range.to}
																													onChange={(e) => handleChangeTypeInputs(e, _test.id_exam, "to", _range.id_external_reception)} invalid={_range.to === "" ? true : false}
																												/>
																												<InputGroupAddon addonType='prepend'>
																													<InputGroupText color='danger' onClick={() => handleDeleteTestReception(_test.id_exam, _range.id_external_reception)}>
																														<i className='icofont icofont-ui-delete'></i>
																													</InputGroupText>
																												</InputGroupAddon>
																												<FormFeedback>Debes llenar este campo</FormFeedback>
																											</InputGroup>
																										</FormGroup>
																									 </Col>
																								</Row>
																							)
																						})
																					}
																					<Row>
																						<Col sm="6" md="6" lg="6"></Col>
																						<Col sm="6" md="6" lg="6">
																							<Button className='btn btn-pill btn-xs' color='secondary' onClick={() => handleAddTestReception(_test.id_exam)}	>
																								<i className='icofont icofont-plus'></i> Intervalo
																							</Button>
																						</Col>
																					</Row>
																				</td>
																			</tr>
																		</>
																	)
																})
															}
														</tbody>
													</Table>
											}
										</div>
										</Card>
									</Col>
								</Row>			
								<Row className='mt-2'>
									<Col sm="12" md="12" lg="12" className='text-right'>
										<Button outline color='primary' onClick={saveRange} className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""}>
											{save}
										</Button>
									</Col>
								</Row>			
							</CardBody>
					</Col>
				</Row>
				<ModalExternalLaboratories
					{
						... {
							modalNewPrice, toggleModalNewPrice, modalTitle, 

							id_exam, price, from_price, to_price,

							handleInputChangeNewPrice, handlePickerValues,

							saveCreatePrice, loading, validationNewPrice
						}
					}
				/>
			</Container>
		</Fragment>
	)
}

import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from '../forms/useForm';
import { sendRequest } from '../requests/useRequest';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { validarSesion } from '../tokens/useToken';
import moment from 'moment';

export const useUpdateLaboratories = () => {

    const history = useHistory();

    const { laboratoriesId } = useParams();

    //#region MODALS
    const [modalTitle, setModalTitle] = useState("");

    const [modalNewPrice, setModalNewPrice] = useState(false);

    const toggleModalNewPrice = () => {
        setModalNewPrice(!modalNewPrice);
    }
    //#endregion

    const [informationLaboratory, setInformationLaboratory] = useState({});
    const [listExams, setListExams] = useState([]);

    const [week, setweek] = useState([
        {
            value: 1,
            label: "L"
        },
        {
            value: 2,
            label: "M"
        },
        {
            value: 3,
            label: "X"
        },
        {
            value: 4,
            label: "J"
        },
        {
            value: 5,
            label: "V"
        },
        {
            value: 6,
            label: "S"
        },
        {
            value: 7,
            label: "D"
        },
    ]);

    const [createSweet] = useSweetAlert();

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getReferencesExams();

    }, []);

    useEffect(() => {

        if (laboratoriesId !== undefined) {
            getExternalLaboratoryTests(laboratoriesId);
        }

    }, [history.location])

    useEffect(() => {

        if (laboratoriesId !== undefined) {
            getInformationExternalLaboratory(laboratoriesId);
        }

    }, [laboratoriesId]);


    //#region FORMS
    const [formExamProfile, handleInputChangeExamProfile, , handleUpdateValuesExamProfile, handleSelectValues, , handleDinamicInputExamProfile] = useForm({
        id_exam_profile: 0,
        id_external_laboratory_test_price: 0,
        externalTest: []
    });

    const {
        id_exam_profile,
        id_external_laboratory_test_price,
        externalTest,
    } = formExamProfile;

    const [formNewPrice, handleInputChangeNewPrice, , handleUpdateValuesNewPrice, , handlePickerValues] = useForm({
        id_exam: 0,
        price: 0.0,
        from_price: "",
        to_price: ""
    });

    const {
        id_exam, price, from_price, to_price
    } = formNewPrice;

    //#endregion

    //#region VALIDATIONS
    const [validationNewPrice, setValidationNewPrice] = useState({
        id_exam_valid: false,
        price_valid: false,
        from_valid: false,
    });
    //#endregion

    //#region GET METHODS
    const getInformationExternalLaboratory = async (id_external_laboratory) => {
        const request = await _handleRequest("GET", `ExternalLaboratories/ExternalLaboratoryById/${id_external_laboratory}`, "Laboratorios externos");

        if (request !== null) {

            setInformationLaboratory({
                id_external_laboratory: request.id_external_laboratory,
                name: request.name
            });
        }
        else {
            setInformationLaboratory({});
        }
    }

    const getReferencesExams = async () => {
        const response = await _handleRequest("GET", "Exam/ListReferenceExams", "Examenes referenciados");

        if (response !== null && response.length > 0) {

            let _ListExams = [];

            response.forEach(_exam => {
                _ListExams.push({
                    label: _exam.code + " " + _exam.name,
                    value: _exam.id_exam,
                    code: _exam.code
                });
            });

            setListExams(_ListExams);
        }
        else {
            setListExams([]);
        }
    }

    const getExternalLaboratoryTests = async (id_external_laboratory) => {

        const response = await _handleRequest("GET", `ExternalLaboratories/GetTestExternalLaboratory/${id_external_laboratory}`);

        if (response !== null && response.length > 0) {

            let listTest = [];

            response.forEach(_exam => {

                let listRanges = [];

                _exam.listRanges.forEach(_range => {
                    let _listDaysOfWeek = (_range.days_of_week !== "" && _range.days_of_week !== null) ? _range.days_of_week.split(",") : [];
                    let _days = [];

                    _listDaysOfWeek.forEach(_day => {
                        let _nameDay = "";

                        switch (_day) {
                            case "1":
                                _nameDay = "L";
                                break;

                            case "2":
                                _nameDay = "M";
                                break;

                            case "3":
                                _nameDay = "X";
                                break;

                            case "4":
                                _nameDay = "J";
                                break;

                            case "5":
                                _nameDay = "V";
                                break;

                            case "6":
                                _nameDay = "S";
                                break;

                            case "7":
                                _nameDay = "D";
                                break;
                        }

                        _days.push({
                            label: _nameDay,
                            value: _day
                        });
                    });

                    let id_external_reception = (Date.now() + Math.random());

                    listRanges.push({
                        id_external_reception: id_external_reception,
                        id_exam: _range.id_exam,
                        id_external_laboratory: _range.id_external_laboratory,
                        id_external_laboratory_test_reception: _range.id_external_laboratory_test_reception,
                        from: _range.from,
                        to: _range.to,
                        days_of_week: _days
                    });
                })

                listTest.push({
                    id_tmp_external: (Date.now() + 1.2),
                    id_external_laboratory: _exam.id_external_laboratory,
                    id_exam: _exam.id_exam,
                    id_price: _exam.id_price,
                    code_exam: _exam.code_exam,
                    name_exam: _exam.name_exam,
                    price: _exam.price,
                    valid_from: _exam.from,
                    valid_to: _exam.to,
                    listRanges: listRanges
                });
            });

            handleUpdateValuesExamProfile({
                ...formExamProfile,
                externalTest: [...listTest]
            });
        }
    }

    //#endregion

    //#region HANDLE METHODS    
    const handleSelectExamChange = async (e) => {

        if (e.value === undefined) {
            createSweet("warning", "warning", "Selecciona un examen valido", "");

            return;
        }

        handleUpdateValuesNewPrice({
            id_exam: e,
            price: 0.0,
            from_price: new Date(),
            to_price: ""
        });

        setModalTitle("Nuevo precio");

        toggleModalNewPrice();
    }

    const handleChangeTypeInputs = (e, _id_exam, _nameInput, _id_tmp_external = null) => {

        let nameInput = "";
        let valueInput = "";

        if (_nameInput === "days_of_week") {
            nameInput = _nameInput;
            valueInput = e;
        }
        else {
            nameInput = e.target.name;
            valueInput = e.target.value;
        }

        let _copyExternalTest = externalTest.map(_test => {

            if (_test.id_exam === _id_exam) {

                if (nameInput === "price") {
                    _test.price = valueInput;
                }
                else {
                    let _copyExternalReceptions = _test.listRanges.map(_reception => {
                        if (_id_tmp_external === _reception.id_external_reception) {

                            switch (nameInput) {
                                case "days_of_week":
                                    _reception.days_of_week = valueInput;

                                    break;
                                case "from":
                                    _reception.from = valueInput;

                                    break;

                                case "to":
                                    _reception.to = valueInput;

                                    break;
                            }
                        }

                        return _reception;
                    });

                    _test.listExternalLaboratoryTestReceptions = _copyExternalReceptions;
                }
            }

            return _test;
        })

        handleUpdateValuesExamProfile({
            ...formExamProfile,
            externalTest: [..._copyExternalTest]
        });
    }

    const handleAddTestReception = (_id_exam) => {

        let _copyExternalReceptions = externalTest.map(_test => {
            if (_test.id_exam === _id_exam) {

                _test.listRanges.push({
                    id_external_reception: Date.now(),
                    id_exam: _id_exam,
                    id_external_laboratory: _test.id_external_laboratory,
                    id_external_laboratory_test_reception: null,
                    days_of_week: [],
                    from: '',
                    to: ''
                });
            }

            return _test;
        });

        handleUpdateValuesExamProfile({
            ...formExamProfile,
            externalTest: [..._copyExternalReceptions]
        });
    }

    const handleDeleteTestReception = (_id_exam, _id_tmp_external) => {

        let _copyExternalReceptions = externalTest.map(_test => {

            if (_test.id_exam === _id_exam) {

                let _copyReceptions = _test.listRanges.filter(_reception => {
                    if (_reception.id_external_reception === _id_tmp_external) {
                        return false;
                    }

                    return true;
                });

                _test.listRanges = _copyReceptions;
            }

            return _test;
        });

        handleUpdateValuesExamProfile({
            ...formExamProfile,
            externalTest: [..._copyExternalReceptions]
        });
    }
    //#endregion

    //#region REQUEST METHODS
    const saveCreatePrice = async () => {
        setLoading(true);

        if (validationsPrices()) {
            setLoading(false);
            return;
        }

        let data = {
            id_external_laboratory: laboratoriesId,
            id_exam: formNewPrice.id_exam.value,
            id_profile: 0,
            price: formNewPrice.price,
            from: moment(formNewPrice.from_price._d).format("YYYY-MM-DD HH:mm:ss"),
            to: (formNewPrice.to_price === "") ? null : moment(formNewPrice.to_price._d).format("YYYY-MM-DD HH:mm:ss"),
        };

        const request = await _handleRequest("POST", "Price/Create", "Registrar nuevo precio", data);

        if (request !== null) {
            setLoading(false);
            createSweet("create", "success", "Exito!", "Se agrego correctamente");
            getExternalLaboratoryTests(laboratoriesId);
            toggleModalNewPrice();
        }

        setLoading(false);
    }

    const saveRange = async () => {
        setLoading(true);

        if (validTestRanges()) {
            setLoading(false);

            return;
        }

        let listTest = [];

        externalTest.forEach(_test => {

            let _copyListRanges = [];

            _test.listRanges.forEach(_range => {

                let _listTimes = [];

                _range.days_of_week.forEach(_day => {
                    _listTimes.push(_day.value);
                });

                _copyListRanges.push({
                    id_external_laboratory_test_reception: _range.id_external_laboratory_test_reception,
                    from: _range.from,
                    to: _range.to,
                    days_of_week: _listTimes.join(',')
                });
            });

            listTest.push({
                id_external_laboratory: _test.id_external_laboratory,
                id_exam: _test.id_exam,
                listRanges: _copyListRanges
            });
        });

        const response = await _handleRequest("POST", "ExternalLaboratories/CreateOrUpdateRanges", "Crear rangos", listTest);

        if (response !== null) {
            setLoading(false);
            createSweet("success", "success", "Se creo correctamente un exámen de referencia ");
        }

        setLoading(false);
    }
    //#endregion    

    //#region VALIDATIONS
    const validationsPrices = () => {
        let statusValidation = false;
        let newValidations = {};
        let actualDay = moment().format("YYYY-MM-DD");

        if (formNewPrice.id_exam.value === undefined) {
            newValidations = {
                ...newValidations,
                id_exam_valid: true
            };

            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                id_exam_valid: false
            };
        }

        if (formNewPrice.price === "" || formNewPrice.price === "0" || formNewPrice.price === 0 || formNewPrice.price <= 0) {
            newValidations = {
                ...newValidations,
                price_valid: true
            };

            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                price_valid: false
            };
        }

        if (formNewPrice.from_price === "") {
            newValidations = {
                ...newValidations,
                from_valid: true
            };

            statusValidation = true;
        }
        else if (moment(formNewPrice.from_price).format("YYYY-MM-DD") < actualDay) {
            newValidations = {
                ...newValidations,
                from_valid: true
            };

            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                from_valid: false
            };
        }

        setValidationNewPrice(newValidations);

        return statusValidation;
    }

    const validTestRanges = () => {
        let statusValidation = false;

        if (formExamProfile.externalTest.length > 0) {

            formExamProfile.externalTest.forEach(_test => {
                if (_test.listRanges.length > 0) {
                    _test.listRanges.forEach(_range => {
                        if (_range.days_of_week === "" || _range.days_of_week.length === 0) {
                            statusValidation = true;
                        }

                        if (_range.from === "") {
                            statusValidation = true;
                        }

                        if (_range.to === "") {
                            statusValidation = true;
                        }
                    })
                }
            });
        }
        else {
            statusValidation = true;
        }

        return statusValidation;
    }
    //#endregion

    //GLOBAL REQUEST 
    const _handleRequest = async (method, methodUrl, nameMethod, body = null, isMultipartForm = null) => {
        let requestOptions = {
            method: method
        };

        if (body !== null) {
            if (isMultipartForm !== null && isMultipartForm) {
                requestOptions = {
                    ...requestOptions,
                    body: body
                };
            }
            else {
                requestOptions = {
                    ...requestOptions,
                    body: JSON.stringify(body)
                };
            }
        }

        const response = await sendRequest(requestOptions, methodUrl, ((isMultipartForm !== null && isMultipartForm) ? "multipart/form-data" : "application/json"));

        if (response.code === 200) {
            return response.data;
        }
        else if (response.code === 401) {
            validarSesion(history, response.code, _handleRequest);
        }
        else if (response.code === 500) {
            createSweet("error", "error", nameMethod, "Ocurrio un error en el servidor");
        }
        else {
            createSweet("warning", "warning", nameMethod, response.data.msg);
        }

        return null;
    }

    return {
        //MODALS
        modalTitle, modalNewPrice, toggleModalNewPrice, loading,

        //MODELS NEW PRICE
        id_exam, price, from_price, to_price,

        //MODELS
        id_exam_profile, id_external_laboratory_test_price, price, externalTest,

        //LIST
        informationLaboratory, listExams, week,

        //HANDLE METHODS
        handleInputChangeNewPrice, handlePickerValues,

        handleSelectExamChange, handleChangeTypeInputs, handleDeleteTestReception,
        handleAddTestReception,

        //REQUEST METHODS
        saveCreatePrice, saveRange,

        //VALIDATIONS 
        validationNewPrice
    }
}
